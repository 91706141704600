body {
    margin: 0;
    padding: 0;
    background-color: #0f0800;
}
p {
    color: #423d17;
}
.canvas {
    display: block;
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}
.projects {
    display: block;
    padding-top: 100vh;
    width: 100vw;
    height: 100vh;
}
.code {
    white-space: pre-wrap;
    padding: 10;
    overflow: hidden;
    font-family: 'Roboto Mono', monospace;
    font-size: 12px;
}

.animate-code-0 {
    position: fixed;
    animation: slideDown 25s linear infinite;
}
.animate-code-1 {
    position: fixed;
    animation: slideDown 19s linear infinite;
}

.gap {
    height: 100vh;
}

.upside-down {
    position: absolute;
    height: 100vh;
    top: 0;
    bottom: 0;
    transform: rotate(180deg);
    width: 100vw;
}

.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

@keyframes slideDown {
    100% {
        transform: translateY(-100%);
    }
}
